judokaProfileCareer_toggleMedalsDetailsMd();
judokaProfileResults_toggleNestedDetailsMd();
judokaProfileBetting_toggleNestedDetailsMd();
judokaProfileCareer_toggleMedalsDetailsSm();
judokaProfileResults_toggleNestedDetailsSm();
judokaProfileBetting_toggleNestedDetailsSm();
countryDetails_toggleMedalsDetailsMd();
countryDetails_toggleMedalsDetailsSm();
index_selectTabAndItsContent();
index_selectTabAndItsContentStatistics();

/* Generic helpers */

function getEntriesRow(sameRow) {
  let siblingRow = sameRow;
  let entriesRow = null;

  while ((siblingRow = siblingRow.nextElementSibling)) {
    if (siblingRow.classList.contains("entries-row")) {
      entriesRow = siblingRow;
      break;
    }
  }

  return entriesRow;
}

function generic_selectTabAndItsContent(
  itemsTabLabelsSelector,
  tabContentElementsSelector
) {
  const itemsTabLabels = document.querySelectorAll(itemsTabLabelsSelector);

  if (itemsTabLabels.length) {
    const onTabLabelClick = (evt) => {
      let selectedIdx;

      for (let idx = 0; idx < itemsTabLabels.length; idx++) {
        const currentTabLabel = itemsTabLabels[idx];

        if (currentTabLabel === evt.target) {
          currentTabLabel.parentElement.classList.add("selected");
          selectedIdx = idx;
        } else {
          currentTabLabel.parentElement.classList.remove("selected");
        }
      }

      const tabContentElements = document.querySelectorAll(
        tabContentElementsSelector
      );

      for (let idx = 0; idx < tabContentElements.length; idx++) {
        const currentTabContentElement = tabContentElements[idx];

        if (idx === selectedIdx) {
          currentTabContentElement.classList.add("visible");
        } else {
          currentTabContentElement.classList.remove("visible");
        }
      }
    };

    // Initialization
    for (let item of itemsTabLabels) {
      item.addEventListener("click", onTabLabelClick);
    }

    itemsTabLabels[0].click();
  }
}

function onItemToggle(evt) {
  const chevron = evt.target;
  const sameRow = chevron.parentElement.parentElement;
  const entriesRow = getEntriesRow(sameRow);

  if (entriesRow) {
    const entriesWrapper = entriesRow.querySelector(
      ".entries-cell .entries-wrapper"
    );

    if (entriesRow.classList.contains("collapsed")) {
      entriesRow.classList.remove("collapsed");
      chevron.classList.add('active')
      entriesWrapper.style.removeProperty("margin-top");
    } else {
      chevron.classList.remove('active')
      entriesRow.classList.add("collapsed");
      entriesWrapper.style.setProperty(
        "margin-top",
        `-${entriesWrapper.clientHeight}px`
      );
    }
  }
}

function generic_toggleNestedDetailsMd(
  selectorQueryPrefix,
  tableClass = "nested-md"
) {
  const itemsToToggle = document.querySelectorAll(
    `${selectorQueryPrefix} table.${tableClass} tr.body > .controls > span`
  );

  if (itemsToToggle.length) {
    // Initialization
    for (let item of itemsToToggle) {
      // Locate subtable
      const sameRow = item.parentElement.parentElement;
      const entriesRow = getEntriesRow(sameRow);

      if (entriesRow) {
        item.addEventListener("click", onItemToggle);

        const entriesWrapper = entriesRow.querySelector(
          ".entries-cell .entries-wrapper"
        );
        
        if (entriesRow.classList.contains("collapsed")) {
          entriesWrapper.style.setProperty(
            "margin-top",
            `-${entriesWrapper.clientHeight}px`
          );
        }
      }
    }
  }
}

function onItemShow(evt) {
  const chevron = evt.target;
  const sameRow = chevron.parentElement.parentElement;
  const entriesRow = getEntriesRow(sameRow);

  if (entriesRow) {
    let eventTable = entriesRow.parentElement.parentElement;

    eventTable.classList.remove("collapsed");

    const entriesWrapper = entriesRow.querySelector(
      ".entries-cell .entries-wrapper"
    );

    entriesWrapper.style.removeProperty("margin-top");
  }
}

function onItemHide(evt) {
  const chevronUp = evt.target;

  const entriesWrapper = chevronUp.parentElement.parentElement;

  entriesWrapper.style.setProperty(
    "margin-top",
    `-${entriesWrapper.clientHeight}px`
  );

  const eventTable =
    entriesWrapper.parentElement.parentElement.parentElement.parentElement;

  eventTable.classList.add("collapsed");
}

function generic_toggleNestedDetailsSm(
  selectorQueryPrefix,
  divClass = "nested-sm",
  tableClass = "element-table"
) {
  const itemsOnClickShow = document.querySelectorAll(
    `${selectorQueryPrefix} div.${divClass} .${tableClass} tr > .controls > img.chevron-down`
  );

  if (itemsOnClickShow.length) {
    // Initialization
    for (let item of itemsOnClickShow) {
      // Locate list-of-subtable wrapper
      const sameRow = item.parentElement.parentElement;
      const entriesRow = getEntriesRow(sameRow);

      if (entriesRow) {
        item.addEventListener("click", onItemShow);

        const entriesWrapper = entriesRow.querySelector(
          ".entries-cell .entries-wrapper"
        );

        entriesWrapper.style.setProperty(
          "margin-top",
          `-${entriesWrapper.clientHeight}px`
        );

        const chevronUp = entriesRow.querySelector(
          ".footer-bar img.chevron-up"
        );

        chevronUp.addEventListener("click", onItemHide);
      }
    }
  }
}

// judoka-profile-career

function judokaProfileCareer_toggleMedalsDetailsMd() {
  generic_toggleNestedDetailsMd(
    ".judoka-profile-section .profile-tab-content.career",
    "medals-md"
  );
}

function judokaProfileResults_toggleNestedDetailsMd() {
  generic_toggleNestedDetailsMd(
    ".judoka-profile-section .profile-tab-content.results"
  );
}

function judokaProfileBetting_toggleNestedDetailsMd() {
  generic_toggleNestedDetailsMd(
    ".judoka-profile-section .profile-tab-content.betting"
  );
}

function countryDetails_toggleMedalsDetailsMd() {
  generic_toggleNestedDetailsMd(
    ".country-details-section .country-details-content",
    "medals-md"
  );
}

function judokaProfileCareer_toggleMedalsDetailsSm() {
  generic_toggleNestedDetailsSm(
    ".judoka-profile-section .profile-tab-content.career",
    "medals-sm",
    "event-table"
  );
}

function judokaProfileResults_toggleNestedDetailsSm() {
  generic_toggleNestedDetailsSm(
    ".judoka-profile-section .profile-tab-content.results"
  );
}

function judokaProfileBetting_toggleNestedDetailsSm() {
  generic_toggleNestedDetailsSm(
    ".judoka-profile-section .profile-tab-content.betting"
  );
}

function countryDetails_toggleMedalsDetailsSm() {
  generic_toggleNestedDetailsSm(
    ".country-details-section .country-details-content",
    "medals-sm",
    "event-table"
  );
}

function index_selectTabAndItsContent() {
  const itemsTabLabelsSelector =
    ".home-top-section .home-top-content .tabs-header > div > span";
  const tabContentElementsSelector =
    ".home-top-section .home-top-content .tabs-content > .tab-content";

  generic_selectTabAndItsContent(
    itemsTabLabelsSelector,
    tabContentElementsSelector
  );
}

function index_selectTabAndItsContentStatistics() {
  const itemsTabLabelsSelector =
    ".judo-statistics-body .tabs-header > div > span";
  const tabContentElementsSelector =
    ".judo-statistics-body .tabs-content > .tab-content";

  generic_selectTabAndItsContent(
    itemsTabLabelsSelector,
    tabContentElementsSelector
  );
}