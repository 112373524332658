var jquery = require("jquery");
window.$ = window.jQuery = jquery;
import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'lity/dist/lity.js'
import 'lity/dist/lity.css'
import 'lightgallery.js'
import 'lg-thumbnail.js'
import 'lightgallery.js/dist/css/lightgallery.css'
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css'
import './global'
import styleSelect from '@szelep/styleselect'
import './intersection-observer'
import CircleType from 'circletype'

const SCROLL_Y_SIZE = 200;
headerScrollClass();
hamburgerMenu();
isMenuItemActive();

window.setLanguage = function setLanguage(lang) {
  $('#_lang').val(lang);
  $('#langSelForm').submit();
  return true;
}

var portraitName = document.getElementById('portraitName')
if (portraitName) {
  const circleType = new CircleType(document.getElementById('portraitName'));
  circleType.radius(310);
}

var sidebarSlide = document.getElementsByClassName('splide');
if (sidebarSlide) {
  for ( var i = 0, len = sidebarSlide.length; i < len; i++ ) {
    new Splide(sidebarSlide[ i ], {
      perMove: 1,
      perPage: 1,
      autoplay: true,
      fixedWidth  : 350,
      height      : 320,
      gap: 100,
      cover       : true,
      pagination  : false,
      arrows: false,
      type: 'slide',
      interval: 3000,
      rewind: true,
      breakpoints: {
        425: {
          fixedWidth  : 300,
        },
      }
    }).mount();
  }
}

/* Common layout */
var eventDetailsGallery = document.getElementById('eventDetailsGallery')
if (eventDetailsGallery) {
  lightGalleryCreate(eventDetailsGallery)
}

var judokaProfilePhotoList = document.getElementById('judokaProfilePhotoList')
if (judokaProfilePhotoList) {
  lightGalleryCreate(judokaProfilePhotoList)
}

var judoEventPhotosGallery = document.getElementById('judoEventPhotosGallery')
if (judoEventPhotosGallery) {
  lightGalleryCreate(judoEventPhotosGallery)
}

var relatedJudoPhotosGallery = document.getElementById('relatedJudoPhotosGallery')
if (relatedJudoPhotosGallery) {
  lightGalleryCreate(relatedJudoPhotosGallery)
}

function lightGalleryCreate(id) {
  lightGallery(id, {
    selector: 'a',
    mode: 'lg-fade',
    cssEasing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    thumbnail: true,
    animateThumb: true,
    download: false,
    downloadUrl: false
  });
}

function headerScrollClass() {
  let header = document.querySelector("header");
  if (header) {
    if (window.scrollY > SCROLL_Y_SIZE) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > SCROLL_Y_SIZE) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }
}

function hamburgerMenu() {
  let button = document.querySelector(".header-button");
  let menu = document.querySelector("header");
  if (button) {
    button.addEventListener("click", () => {
      menu.classList.toggle("active");
      button.classList.toggle("active");
      if (menu.classList.contains("active")) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
      let menuItemHasChildren = document.querySelectorAll('header li.menu-item.menu-item-has-children')
      if (menuItemHasChildren) {
        Array.prototype.forEach.call(menuItemHasChildren, function (menuItem) {
          if (menuItem.classList.contains('active')) {
            menuItem.classList.remove('active')
          }
        })
      }
    });
  }
}

function isMenuItemActive() {
  let menus = document.querySelectorAll('header li.menu-item')
  if (menus) {
    Array.prototype.forEach.call(menus, function (menu) {
      if (menu.querySelector('a')) {
        menu.querySelector('a').addEventListener('click', function (event) {
          let menuItemHasChildren = document.querySelectorAll('header li.menu-item.menu-item-has-children')
          if (menuItemHasChildren) {
            Array.prototype.forEach.call(menuItemHasChildren, function (menuItem) {
              if (menuItem.children[0].getAttribute('data-item-id') != event.currentTarget.getAttribute('data-item-id') && menuItem.classList.contains('active')) {
                menuItem.classList.remove('active')
              }
            })
          }
          if (menu.classList.contains('menu-item-has-children')) {
            if (menu.classList.contains('active')) {
              menu.classList.remove('active')
            } else {
              menu.classList.add('active')
            }
            event.preventDefault()
          }
        })
      }
    })
  }
}
  
document.addEventListener('DOMContentLoaded', () => {
  setStyleSelect()
})

function setStyleSelect() {
  let questionYearSelect = document.getElementById('EventSearch_year')
  let questionEventTypesSelect = document.getElementById('EventSearch_type')
  let questionEventCountrySelect = document.getElementById('EventSearch_country')
  let questionNationalityJudoka = document.getElementById('question-nationality-judoka')
  let questionGenderJudoka = document.getElementById('question-gender-judoka')
  let questionYearJudoka = document.getElementById('question-year-judoka')
  let questionMonthJudoka = document.getElementById('question-month-judoka')
  let questionDayJudoka = document.getElementById('question-day-judoka')
  let questionCountryBirthday = document.getElementById('question-country-birthday')

  let questionWhichStats = document.getElementById('question-which-stats')
  let questionAchievementStats = document.getElementById('question-achievement-stats')
  let questionEventsStats = document.getElementById('question-events-stats')
  let questionWeightclassStats = document.getElementById('question-weightclass-stats')
  let questionFromStats = document.getElementById('question-from-stats')
  let questionUntilStats = document.getElementById('question-until-stats')
  let questionCountryStats = document.getElementById('question-country-stats')
  let questionSubjectContact = document.getElementById('question-subject-contact')
  let questionOpponentProfile = document.getElementById('question-opponent-profile')
  let questionComparableTournament = document.getElementById('question-comparable-tournament')
  let questionWeightCategory = document.getElementById('question-weight-category')
  if (questionWeightCategory) {
    styleSelect(questionWeightCategory)
  }
  if (questionComparableTournament) {
    styleSelect(questionComparableTournament)
  }
  if (questionOpponentProfile) {
    styleSelect(questionOpponentProfile)
  }
  if (questionSubjectContact) {
    styleSelect(questionSubjectContact)
  }
  if (questionCountryStats) {
    styleSelect(questionCountryStats)
  }
  if (questionUntilStats) {
    styleSelect(questionUntilStats)
  }
  if (questionWhichStats) {
    styleSelect(questionWhichStats)
  }
  if (questionFromStats) {
    styleSelect(questionFromStats)
  }
  if (questionAchievementStats) {
    styleSelect(questionAchievementStats)
  }
  if (questionEventsStats) {
    styleSelect(questionEventsStats)
  }
  if (questionWeightclassStats) {
    styleSelect(questionWeightclassStats)
  }
  if (questionCountryBirthday) {
    styleSelect(questionCountryBirthday)
  }

  if (questionYearJudoka) {
    styleSelect(questionYearJudoka)
  }
  if (questionMonthJudoka) {
    styleSelect(questionMonthJudoka)
  }
  if (questionDayJudoka) {
    styleSelect(questionDayJudoka)
  }
  if (questionGenderJudoka) {
    styleSelect(questionGenderJudoka)
  }
  
  if (questionYearSelect) {
    styleSelect(questionYearSelect)
  }
  if (questionEventTypesSelect) {
    styleSelect(questionEventTypesSelect)
  }
  if (questionEventCountrySelect) {
    styleSelect(questionEventCountrySelect)
  }

  if (questionNationalityJudoka) {
    styleSelect(questionNationalityJudoka)
  }
}

const atags = Array.from(document.querySelectorAll('.photos-list a'));
if (atags) {
  atags.forEach(atag => {
    var img = new Image();
    img.addEventListener('load', () => {
      let imgW = img.width,
      imgH = img.height;
      atag.dataset.originalSize = `${imgW}x${imgH}`;      
      if (imgW > imgH + 1) {
        atag.classList.add('judo-object-position-center')
      } else {
        atag.classList.add('judo-object-position-top')
      }
    });
    img.src = atag.href;    
  });
}
